import { Chart } from 'chart.js'

export default function setupChartDefaults() {
  Chart.defaults.interaction.intersect = false
  Chart.defaults.interaction.mode = 'index'

  Chart.defaults.scales.category = {
    ...Chart.defaults.scales.category,
    grid: { display: false },
  }

  Chart.defaults.datasets.bar = {
    ...Chart.defaults.datasets.bar,
    borderWidth: 1,
    barPercentage: 1,
  }
  Chart.defaults.datasets.line = {
    ...Chart.defaults.datasets.line,
    borderWidth: 2,
  }
  Chart.defaults.datasets.pie = {
    ...Chart.defaults.datasets.pie,
  }
}
